import React from 'react';
import './BRPage.css';
import { Grid, Container} from 'semantic-ui-react';
import { Helmet } from 'react-helmet-async'

const BRPage = () => {

    return (

        <div className="br-container">
            <Helmet>
                <head>Referal Program</head>
                <meta name="description" content="Managing properties on the side can lead to serious issues if you're not fully committed to the 
                    business. Things like maintaining separate operating accounts, using proper documentation, ensuring 
                    both the owner and tenant are properly insured, and adhering to city regulations are just a few 
                    ways you could run into problems if you’re not deeply focused on property management."/>
                <link rel="canonical" href="/brokers-realtors" />
            </Helmet>
            <h1 className="br-title">Benefits of Refering Your Client To Delta Property Management Group, LLC.</h1>
            <p>&nbsp;</p>
            <Container className="br-body">
                <h2 className="br-title-2"> Property Management is a Hassle</h2>
                <p className="font-size-brpage">Managing properties on the side can lead to serious issues if you're not fully committed to the 
                    business. Things like maintaining separate operating accounts, using proper documentation, ensuring 
                    both the owner and tenant are properly insured, and adhering to city regulations are just a few 
                    ways you could run into problems if you’re not deeply focused on property management.</p>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                <h2 className="br-title-2"> Great Referral Fees</h2>
                <p className="font-size-brpage">A property owner that becomes a client as a result of any referral will given a referal fee. 
                    The fee is based on the monthly rent that Delta Group aquires. The break down is listed below:</p>
                <ul>
                    <li>$1,500-$2,000 per month in rent <strong>- $150 referral fee</strong></li>
                    <li>$2,000-$3,000 per month in rent <strong>- $200 referral fee</strong></li>
                    <li>$3,000-$4,000 per month in rent <strong>- $300 referral fee</strong></li>
                    <li>Over $4,000 per month in rent <strong>- &nbsp;&nbsp;&nbsp;$400+ referral fee</strong></li>
                </ul>
                <p>For referrals of commercial clients, your referal fee will be calculated off the first month's management fees.</p>
            </Container>
        </div>
    
    );
  };
  
  export default BRPage;