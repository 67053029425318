import React from 'react';
import { Container, Grid, Image } from 'semantic-ui-react';
import './OurTeam.css';
import presidentImage from './pictures/mHeadshot.png'; // Replace with actual image path
import vpImage from './pictures/mHeadshot.png'; // Replace with actual image path

const OurTeam = () => {
  return (
    <div className="our-team-page">

      {/* Property Manager Section */}
      <section className="section-white">
        <Container>
          <Grid stackable columns={2} verticalAlign="  ">
            <Grid.Column>
              <Image src={vpImage} alt="Vice President" className="team-image" />
            </Grid.Column>
            <Grid.Column>
              <h2 className="team-header">Our Property Manager</h2>
              <p className="team-description">
                Aundrea Dirks, our dedicated Property Manager, brings a 
                wealth of experience and a client-first approach to her role. With years of expierence and an 
                eye for detail and a passion for creating seamless experiences, Andrea 
                expertly manages all aspects of property operations, ensuring that both 
                owners and tenants receive exceptional service. Her commitment to efficiency, 
                communication, and tailored property solutions makes her an invaluable asset 
                to our team and the communities we serve.
              </p>
            </Grid.Column>
          </Grid>
        </Container>
      </section>

      {/* Vice President Section */}
      <section className="section-blue">
        <Container>
          <Grid stackable columns={2} verticalAlign="middle">
            <Grid.Column>
              <Image src={vpImage} alt="Vice President" className="team-image" />
            </Grid.Column>
            <Grid.Column>
              <h2 className="team-header2">Our Vice President</h2>
              <p className="team-description2">
                Jared Knaub, our Vice President, oversees day-to-day operations and ensures 
                the highest level of service for our clients and tenants.
              </p>
            </Grid.Column>
          </Grid>
        </Container>
      </section>

      {/* Director of Technology Section */}
      <section className="section-white">
        <Container>
          <Grid stackable columns={2} verticalAlign="middle">
            <Grid.Column>
              <Image src={vpImage} alt="Vice President" className="team-image" />
            </Grid.Column>
            <Grid.Column>
              <h2 className="team-header">Our Director of Technology / Property Manager</h2>
              <p className="team-description">
                 At just 24 years old, Dylan Felgenhauer is already making an impact in the realms
                  of technology and real estate. Graduating with a degree in Computer Science 
                  in August 2023, Dylan joined Delta Group Inc. in July 2024 as the Director of 
                  Technology and Property Manager.
              </p>
            </Grid.Column>
          </Grid>
        </Container>
      </section>

      {/* President Section */}
      <section className="section-blue">
        <Container>
          <Grid stackable columns={2} verticalAlign="middle">
            <Grid.Column>
              <Image src={presidentImage} alt="President" className="team-image" />
            </Grid.Column>
            <Grid.Column>
              <h2 className="team-header2">Our President</h2>
              <p className="team-description2">
              Michael Yingling started his career in real estate in 1974 with construction 
              and property management with “Service is Job One” 
              as a mission statement, Michael takes pride in making sure that this statement 
              is carried out each and every day by his staff, agents, and himself. Michael 
              purchased his first investment property at 18 years old and, over the years,
               has built, sold, managed and developed thousands of residential homes, 
               commercial buildings, businesses, and developments. Michael also served as 
               an elected Township Supervisor for over 15 years. Each client is given 
               first-class service for all their needs. Let his half century of experience and 
               knowledge go to work for you, your family, or your organization. We exist to help 
               you grow and prosper, no matter what size your wants, needs, or aspirations are.
              </p>
            </Grid.Column>
          </Grid>
        </Container>
      </section>

      {/* Mission Section */}
      <section className="section-white">
        <Container>
          <Grid stackable verticalAlign="middle">
            <Grid.Column textAlign="center">
              <h2 className="team-header">Our Mission</h2>
              <p className="team-description mission-text">
                "Service is Job One" is the mission statement we follow, and our mission is to provide exceptional property management services 
                that enhance the value of properties while creating a positive living experience for tenants.
              </p>
            </Grid.Column>
          </Grid>
        </Container>
      </section>
    </div>
  );
};

export default OurTeam;