import 'semantic-ui-css/semantic.min.css'
import React, { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import './App.css';
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import OurTeam from "./pages/OurTeam";
import ContactUsPage from "./pages/ContactUsPage";
import NoPage from "./pages/NoPage";
import TenantLogin from "./pages/TenantLogin"
import OwnerLogin from "./pages/OwnerLogin"
import Pricing from "./pages/Pricing";
import OurServices from "./pages/OurServices";
import RealHelp from "./pages/RealHelp";
import WeServe from "./pages/WeServe"
import BRPage from "./pages/BRPage"
import { Helmet } from 'react-helmet-async'
import HRedirect from "./pages/redirects/HarrisburgRedirect"

const App = () => {

  return (
      <BrowserRouter>
      <Helmet>
        <head>Delta Property Management Group</head>
        <meta name="description" content="Welcome to Delta Property Management Group, LLC. 
        A Harrisburg Area Property Management Group. Delta PMG is a reliable partner that offers
        services for landlords by landlords. We specialize in managing residential properties aswell 
        as commercial properties."/>
        <link rel="canonical" href="/" />
      </Helmet>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="lander" element={<Home />} />
          <Route path="about-us/our-team" element={<OurTeam />} />
          <Route path="contact-us" element={<ContactUsPage />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="tenant-login" element={<TenantLogin />} />
          <Route path="owner-login" element={<OwnerLogin />} />
          <Route path="*" element={<NoPage />} />
          {/* <Route path="real-estate-help" element={<RealHelp />} /> */}
          <Route path="about-us/areas-we-serve" element={<WeServe />} />
          <Route path="harrisburg-area" element={<HRedirect />} />
          <Route path="brokers-realtors" element={<BRPage />} />
          <Route path="inquiring-owners/our-services" element={<OurServices />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;