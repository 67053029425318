import React from 'react';
import { Container, Grid, GridColumn, GridRow } from 'semantic-ui-react';
import './Pricing.css';
import { Helmet } from 'react-helmet-async'

const Pricing = () => {
  return (
    <div className="pricing-page">
      <Helmet>
        <head>Our Fees</head>
        <meta name="description" content="Pricing is very important when it comes to 
        property management. We have prices for a few units to whole apartment buildings 
        to full on retail. There is no initial leasing fees, start up fees, or inspection fees."/>
        <link rel="canonical" href="/pricing" />
      </Helmet>
      <div className="pricing-container">
        <div className="pricing-header-2">
          <h1 className="font-size-pricing">Property Management for Single Family Homes, Townhomes, Condos, and Commercial</h1>
        </div>
        <div className="pricing-header">
          <h2>4%-10% of GROSS RENTS</h2>
          <p>NO INITIAL LEASING FEE, START UP FEE, INSPECTION FEE <br /> (FULLY TAX DEDUCTIBLE)</p>
        </div>

        <div className="pricing-services">
          <Grid className="price-grid">
            <GridColumn className="price-column">
              <div className="pricing-column">
              <h4 className="header-color">PRE-MARKETING</h4>
                <ul className="custom-list">
                  <li className="no-whitespace">Rental Analysis</li>
                  <li className="no-whitespace">Pre-rental Evaluation of Home</li>
                  <li className="no-whitespace">Recommend Improvements if Needed</li>
                  <li className="no-whitespace">Oversee Improvements if Needed</li>
                  <li className="no-whitespace">Set Optimal Rental Price</li>
                </ul>
                <p>&nbsp;</p>
            </div>
            <div className="pricing-column">
            <h4 className="header-color">DAY TO DAY MANAGEMENT</h4>
              <ul className="custom-list">
                <li className="no-whitespace">Handle Tenant Concerns</li>
                <li className="no-whitespace">In-house Maintenance Manager</li>
                <li className="no-whitespace">Excellent Vendor Discounts</li>
                <li className="no-whitespace">Home Inspection Annually</li>
                <li className="no-whitespace">Enforce Terms of Lease</li>
              </ul>
              <p>&nbsp;</p>
          </div>
          <div className="pricing-column">
            <h4 className="header-color">ADVERTISING</h4>
              <ul className="custom-list">
                <li className="no-whitespace">Professional Photography</li>
                <li className="no-whitespace">Ads Placed on Real Estate Sites</li>
                <li className="no-whitespace">Signs Placed on Property</li>
              </ul>
          </div>
            </GridColumn>
            <GridColumn>
              <div className="pricing-column">
            <h4 className="header-color">ACCOUNTING</h4>
              <ul className="custom-list">
                <li className="no-whitespace">Rents Collected by the 5th of the Month</li>
                <li className="no-whitespace">Prompt Payment to Owner</li>
                <li className="no-whitespace">Monthly Statements of All Activity</li>
                <li className="no-whitespace">Deposits Held in Trust Account</li>
                <li className="no-whitespace">1099 and Year-End Statement</li>
              </ul>
              <p>&nbsp;</p>
          </div>
          <div className="pricing-column">
            <h4 className="header-color">PEACE OF MIND</h4>
              <ul className="custom-list">
                <li className="no-whitespace">We Follow Fair Housing Laws</li>
                <li className="no-whitespace">Our Eviction Guarantee</li>
                <li className="no-whitespace">Avoid Tenant Confrontations</li>
                <li className="no-whitespace">Annual Rent Review</li>
              </ul>
          </div>
            </GridColumn>
          </Grid>
        </div>

        <div className="commercial-pricing">
          <div className="unit-type">
            <h4>1-4 UNITS / HOUSES</h4>
            <p>8-10% of monthly rents <br /> (Fully Tax Deductible)</p>
          </div>
          <div className="unit-type">
            <h4>5+ UNITS / HOUSES</h4>
            <p>6-9% of monthly rents <br /> (Fully Tax Deductible)</p>
          </div>
          <div className="unit-type">
            <h4>RETAILS / COMMERCIAL</h4>
            <p>Typically Between 4-7% of monthly rents <br /> (Fully Tax Deductible)</p>
          </div>
        </div>
      </div>

      
      <div className="pricing-container2">
      <div className="pricing-header-2">
          <h1 className="font-size-pricing1">Property Management for Single Family Homes, Townhomes, Condos, and Commercial</h1>
      </div>
      <div className="pricing-header">
        <p className="font-size-pricing2">4%-10% of GROSS RENTS</p>
        <p className="font-size-pricing3">NO INITIAL LEASING FEE, START UP FEE, INSPECTION FEE <br /> (FULLY TAX DEDUCTIBLE)</p>
      </div>

  {/* Use Semantic UI Grid to center the columns */}
  <Grid centered textAlign="left" stackable>
    <GridRow className="header-color1">
    <p>&nbsp;</p>
      <h4 className="header-color1">PRE-MARKETING</h4>
    </GridRow>
    <GridRow>
      <ul className="custom-list1">
        <li className="no-whitespace">Rental Analysis</li>
        <li className="no-whitespace">Pre-rental Evaluation of Home</li>
        <li className="no-whitespace">Recommend Improvements if Needed</li>
        <li className="no-whitespace">Oversee Improvements if Needed</li>
        <li className="no-whitespace">Set Optimal Rental Price</li>
      </ul>
    </GridRow>
    
    <GridRow className="header-color1">
      <h4 className="header-color1">DAY TO DAY MANAGEMENT</h4>
    </GridRow>
    <GridRow>
      <ul className="custom-list1">
        <li className="no-whitespace">Handle Tenant Concerns</li>
        <li className="no-whitespace">In-house Maintenance Manager</li>
        <li className="no-whitespace">Excellent Vendor Discounts</li>
        <li className="no-whitespace">Home Inspection Annually</li>
        <li className="no-whitespace">Enforce Terms of Lease</li>
      </ul>
    </GridRow>

    <GridRow className="header-color1">
      <h4 className="header-color1">ADVERTISING</h4>
    </GridRow>
    <GridRow>
      <ul className="custom-list1">
        <li className="no-whitespace">Professional Photography</li>
        <li className="no-whitespace">Ads Placed on 20 Real Estate Sites</li>
        <li className="no-whitespace">Video Tour on YouTube</li>
        <li className="no-whitespace">Signs Placed on Property</li>
      </ul>
    </GridRow>

    <GridRow className="header-color1">
      <h4 className="header-color1">ACCOUNTING</h4>
    </GridRow>
    <GridRow>
      <ul className="custom-list1">
        <li className="no-whitespace">Rents Collected by the 5th of the Month</li>
        <li className="no-whitespace">Prompt Payment to Owner</li>
        <li className="no-whitespace">Monthly Statements of All Activity</li>
        <li className="no-whitespace">Deposits Held in Trust Account</li>
        <li className="no-whitespace">1099 and Year-End Statement</li>
      </ul>
    </GridRow>

    <GridRow>
      <h4 className="header-color1">PEACE OF MIND</h4>
    </GridRow>
    <GridRow>
      <ul className="custom-list1">
        <li className="no-whitespace">We Follow Fair Housing Laws</li>
        <li className="no-whitespace">Our Eviction Guarantee</li>
        <li className="no-whitespace">Avoid Tenant Confrontations</li>
        <li className="no-whitespace">Annual Rent Review</li>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </ul>
    </GridRow>
  </Grid>

  <div className="commercial-pricing">
    <Grid>
      <GridRow>
        <div className="unit-type">
          <h4>1-4 UNITS / HOUSES</h4>
          <p>8-10% of monthly rents <br /> (Fully Tax Deductible)</p>
        </div>
      </GridRow>
      <GridRow>
        <div className="unit-type">
          <h4>5+ UNITS / HOUSES</h4>
          <p>6-9% of monthly rents <br /> (Fully Tax Deductible)</p>
        </div>
      </GridRow>
      <GridRow>
        <div className="unit-type">
          <h4>RETAILS / COMMERCIAL</h4>
          <p>Typically Between 4-7% <br /> of monthly rents <br /> (Fully Tax Deductible)</p>
        </div>
      </GridRow>
    </Grid>
  </div>
</div>
    </div>
  );
};

export default Pricing;
