import React, { useState } from 'react';
import './pages.css';

const TenantLogin = () => {
  // State for form input values
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // State for error messages
  const [error, setError] = useState('');

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    // Simple validation
    if (!email || !password) {
      setError('Both fields are required.');
      return;
    }

    setError('');

    // Perform authentication logic here (e.g., API call)
    console.log('Email:', email);
    console.log('Password:', password);

    // Example of redirect or further action after successful login
    // navigate('/dashboard'); // if using React Router
  };

  return (
    <div className="login-container">
      <h2>Tenant Login</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default TenantLogin;