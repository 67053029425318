import React, { useState } from "react";
import { Container, GridRow, Grid, Image, GridColumn } from 'semantic-ui-react';
import './WeServe.css';
import MapPNG from './pictures/Map.png'
import { Helmet } from 'react-helmet-async'

const WeServe = () => {

    return (
        <section className="main-container">
            <Helmet>
                <head>Areas We Serve</head>
                <meta name="description" content="Delta Property Management Group which is located in Harrisburg 
                Pennsylvania serves Dauphin County, Cummberland County, Lebanon County, York County, Lancaster County,
                and Perry County. We service everything within a one hour bubble around our home office."/>
                <link rel="canonical" href="/areas-we-serve" />
            </Helmet>
            <Container className="container-1">
                <h1 className="area-title">Areas We Serve</h1>
                <p>&nbsp;</p>
                <h2 className="serve-body">At Delta Property Management Group, LLC., we're with you every step 
                    of the way in managing your rental property. Our team delivers all the 
                    essential services to ensure your investments thrive. We follow a 
                    thorough process to effectively market your rental properties, 
                    obtain tenants that are reliable, and take care of all the 
                    responsibilities that a landlord has. Throughout the process, you can 
                    rest easy, knowing you have a professional team supporting you.</h2>
                    <p>&nbsp;</p>
                <p>&nbsp;</p>
                <h2 className="area-title-3">We Proudly Serve the Following Areas:</h2>
                <p>&nbsp;</p>
                <Image src={MapPNG} />
                <p>&nbsp;</p>
            </Container>
            <Grid className="area-grid" centered>
                    <GridRow className="area-row">
                        <GridColumn className="area-column">
                            <h3 className="area-title-2">Dauphin County</h3>
                        </GridColumn>
                        <GridColumn className="area-column">
                            <h3 className="area-title-2">Cumberland County </h3>
                        </GridColumn>
                        <GridColumn className="area-column">
                            <h3 className="area-title-2">Lebanon County</h3>
                        </GridColumn>
                    </GridRow>
                    <GridRow className="area-row">
                        <GridColumn className="area-column-bottom">
                            <h3 className="area-title-2">York County</h3>
                        </GridColumn>
                        <GridColumn className="area-column-bottom">
                            <h3 className="area-title-2">Lancaster County</h3>
                        </GridColumn>
                        <GridColumn className="area-column-bottom">
                            <h3 className="area-title-2">Perry County</h3>
                        </GridColumn> 
                    </GridRow>
                </Grid>

                <Grid className="area-grid1">
                        <GridColumn className="area-column1">
                            <h3 className="area-title-2">Dauphin County</h3>
                            <h3 className="area-title-2">Cumberland County </h3>
                            <h3 className="area-title-2">Lebanon County</h3>
                        </GridColumn>
                        <GridColumn className="area-column-bottom1">
                            <h3 className="area-title-2">York County</h3>
                            <h3 className="area-title-2">Lancaster County</h3>
                            <h3 className="area-title-2">Perry County</h3>
                        </GridColumn>
                </Grid>
        </section>
    );
  };
  
  export default WeServe;