import React, { useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { Menu, MenuItem, MenuMenu, GridRow, Container, Grid, Icon, Sidebar, Segment, Button, Popup, GridColumn } from 'semantic-ui-react';
import dlogo from './pictures/dlogo.png';
import maintenance from './pictures/maintenance.png'
import ABR from './pictures/ABR.png'
import CDPE from './pictures/CDPE.png'
import CRS from './pictures/CRS.png'
import EHO from './pictures/EHO.png'
import ESBA from './pictures/ESBA.png'
import SRES from './pictures/SRES.png'
import './Layout.css';
import { Helmet } from 'react-helmet-async'

const Layout = () => {
  const [visible, setVisible] = useState(false);

  const handleSidebarToggle = () => setVisible(!visible);

  return (
      <div fluid className="content-container">
        <Helmet>
        <head>Delta Property Management Group</head>
        <meta name="description" content="Welcome to Delta Property Management Group, LLC. 
        A Harrisburg Area Property Management Group. Delta PMG is a reliable partner that offers
        services for landlords by landlords. We specialize in managing residential properties aswell 
        as commercial properties."/>
        <link rel="canonical" href="/" />
      </Helmet>
        {/* Container for desktop and mobile views */}
        <Container fluid className="menu-container">
          <Grid verticalAlign="middle">
            <GridRow fluid className="top-row">
              {/* Hamburger Icon for Mobile */}
              <Grid.Column width={2} only="mobile tablet" textAlign="left">
                <Button className="button-hamburger" icon onClick={handleSidebarToggle}>
                  <Icon name="bars" size="large" />
                </Button>
              </Grid.Column>
              {/* Logo */}
              <Grid.Column width={8} textAlign="center">
                <Link to="/">
                  <img  
                    src={dlogo} 
                    alt="Logo" 
                    className="logo"
                    style={{ cursor: 'pointer' }}/>
                </Link>
              </Grid.Column>
              <Grid.Column width={8} className="right-aligned-items" textAlign="center" only="computer">
                {/* Right-aligned items (hotline or additional links) */}
                <MenuMenu secondary>                  
                  <Menu.Item className="wrap-text">
                    <a href="tel:717-671-7676" style={{ textDecoration: 'none', color: 'inherit' }}>
                      <h4 className ="bold">Current Owner &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;& Tenant Hotline</h4>
                      <h3 className="num-color">717-671-7676</h3>
                    </a>
                  </Menu.Item>
                  <Menu.Item className="wrap-text2">
                    <a href="tel:717-652-8200" style={{ textDecoration: 'none', color: 'inherit' }}>
                      <h4 className="bold">Owners Inquiring About Our Services</h4>
                      <h3 className="num-color">717-497-9172</h3>
                    </a>
                  </Menu.Item>
                  <MenuItem
                    className="menu-restraint"
                    as={Link}
                    style={{ fontSize: '115%' }}
                    to="https://signin.managebuilding.com/manager/public/authentication/login"
                  > 
                    <img  
                    src={maintenance} 
                    alt="Logo" 
                    className="mlogo"
                    style={{ cursor: 'pointer' }}/>
                    Maintenance Request
                  </MenuItem>
                </MenuMenu>
              </Grid.Column>
            </GridRow>

            {/* Full Menu for Desktop */}
            <Grid.Row centered className="grow" only="computer">
              <Menu secondary className="menu-color">
                <MenuMenu className="menu-color">
                  <Menu.Item as={Link} to="/" style={{ textDecoration: 'none', color: 'inherit' }}> <h4>Home</h4></Menu.Item>
                 {/* <Popup 
                    trigger={<Button className="popup-button"><h4>Inquiring Owners</h4></Button>} 
                    on="hover"
                    position="bottom left"
                    hoverable
                  >
                    <Popup.Content>
                      <Menu fluid vertical className="popup-menu">
                        <MenuItem className="popup-menu-item" as={Link} to="/inquiring-owners/our-services">Our Services</MenuItem>
                      </Menu>
                    </Popup.Content> 
                  </Popup> */}
                  <MenuItem className="hover-color" as={Link} to="/inquiring-owners/our-services"><h4>Inquiring Owners</h4></MenuItem>
                  <MenuItem className="hover-color" name="Our Fees" as={Link} to="/pricing"> <h4>Our Fees</h4></MenuItem>
                  {/* <Popup 
                    trigger={<Button className="popup-button"><h4>About Us</h4></Button>} 
                    on="hover"
                    position="bottom left"
                    hoverable
                  >
                    <Popup.Content>
                      <Menu fluid vertical className="popup-menu">
                        <MenuItem className="popup-menu-item" as={Link} to="/about-us/our-team">Our Team</MenuItem> 
                        <MenuItem className="popup-menu-item" as={Link} to="/about-us/areas-we-serve">Areas We Serve</MenuItem>
                      </Menu>
                    </Popup.Content>
                  </Popup> */}
                  <MenuItem className="hover-color" as={Link} to="/about-us/areas-we-serve"><h4>Areas We Serve</h4></MenuItem>
                  <MenuItem name="Owner Login" as={Link} to="https://deltapmg.managebuilding.com/manager/public/authentication/login?returnUrl=%2Fmanager%2Fapp%2Fhomepage%2Fdashboard"> <h4>Owner Login</h4></MenuItem>
                  <MenuItem name="Tenant Login" as={Link} to="https://deltapmg.managebuilding.com/Resident/portal/login"> <h4>Tenant Login</h4></MenuItem>
                 {/*  <MenuItem name="Owner Videos/Resources" as={Link} to="/"> <h4>Owner Videos/Resources</h4></MenuItem> */}
                  <MenuItem name="Brokers/Realtors" as={Link} to="/brokers-realtors"> <h4>Brokers/Realtors</h4></MenuItem>
                  <MenuItem name="Real Estate Help" as={Link} to="https://www.remaxdeltagroup.com"> <h4>Real Estate Help</h4></MenuItem>
                  <MenuItem name="Contact Us" as={Link} to="/contact-us"> <h4>Contact Us</h4></MenuItem>
                </MenuMenu>
              </Menu>
            </Grid.Row>
          </Grid>
        </Container>

        {/* Sidebar for Mobile */}
        <Sidebar.Pushable as={Segment} className="content-segment">
          <Sidebar
            as={Menu}
            animation="overlay"
            icon="labeled"
            inverted
            vertical
            visible={visible}
            onHide={() => setVisible(false)}
            direction="left"
            className="sidebar-dropdown"
          >
            <MenuItem name="Home" className="popup-button-1" as={Link} to="/" onClick={handleSidebarToggle}/>
            {/* <Popup 
                    trigger={<Button className="popup-button"><h4>Inquiring Owners</h4></Button>} 
                    on="hover"
                    position="bottom left"
                    hoverable
                  >
                    <Popup.Content>
                      <Menu fluid vertical className="popup-menu">
                        <MenuItem className="popup-menu-item" as={Link} to="/inquiring-owners/our-services">Our Services</MenuItem>
                      </Menu>
                    </Popup.Content>
                  </Popup> */}
            <MenuItem className="popup-button-1" as={Link} to="/inquiring-owners/our-services" onClick={handleSidebarToggle}>Inquiring Owners</MenuItem>
            <MenuItem name="Our Fees" className="popup-button-1"as={Link} to="/pricing" onClick={handleSidebarToggle}/>
            {/* <Popup 
                    trigger={<Button className="popup-button"><h4>About Us</h4></Button>} 
                    on="hover"
                    position="bottom left"
                    hoverable
                  >
                    <Popup.Content>
                      <Menu fluid vertical className="popup-menu">
                        <MenuItem className="popup-menu-item" as={Link} to="/about-us/our-team">Our Team</MenuItem>
                        <MenuItem className="popup-menu-item" as={Link} to="/about-us/areas-we-serve">Areas We Serve</MenuItem>
                      </Menu>
                    </Popup.Content>
                  </Popup> */}
            <MenuItem className="popup-button-1" as={Link} to="/about-us/areas-we-serve" onClick={handleSidebarToggle}>Areas We Serve</MenuItem>
            <MenuItem name="Owner Login" className="popup-button-1" as={Link} to="https://deltapmg.managebuilding.com/manager/public/authentication/login?returnUrl=%2Fmanager%2Fapp%2Fhomepage%2Fdashboard"/>
            <MenuItem name="Tenant Login" className="popup-button-1" as={Link} to="https://deltapmg.managebuilding.com/Resident/portal/login" />
            {/* <MenuItem name="Owner Videos/Resources" as={Link} to="/" /> */}
            <MenuItem name="Brokers/Realtors" className="popup-button-1" as={Link} to="/brokers-realtors" onClick={handleSidebarToggle}> <h4 className="layout-font-mobile">Brokers/Realtors</h4></MenuItem>
            <MenuItem name="Real Estate Help" className="popup-button-1" as={Link} to="https://www.remaxdeltagroup.com" />
            <MenuItem name="Contact Us" className="popup-button-1" as={Link} to="/contact-us" onClick={handleSidebarToggle}/>
          </Sidebar>

          <Sidebar.Pusher>
            <Segment basic className="content-segment">
              <Outlet/>
            </Segment>
          </Sidebar.Pusher>
        </Sidebar.Pushable>

        {/* Footer */}
        <section className="footer-section-mobile">
          <Container fluid textAlign="left" className="container-foot">
            <Grid textAlign="center" className="pre-footer">
              <Grid.Column className="footer-column1">
                <p className="footer-1">Delta Property Management Group, LLC.</p>
                <p className="footer-2">7839 Allentown Blvd., Suite 800</p>
                <p className="footer-2">Harrisburg, PA 17112</p>
                <p>&nbsp;</p>
                <p className="footer-2">Office Number: &nbsp;&nbsp;&nbsp;&nbsp;<a href="tel:717-652-8200"> (717) 652-8200</a></p>
                <p className="footer-2">Inquiring Owners: &nbsp;<a href="tel:717-497-9172"> (717) 497-9172</a></p>
                <p>&nbsp;</p>
                <p className="footer-2">Office Hours</p>
                <div class="ui three column grid" style={{width: 250}}>
                  <div class="column">
                    <p className="footer-2">Monday</p>
                    <p className="footer-2">Tuesday</p>
                    <p className="footer-2">Wednesday</p>
                    <p className="footer-2">Thursday</p>
                    <p className="footer-2">Friday</p>
                    <p className="footer-2">Saturday</p>
                    <p className="footer-2">Sunday</p>
                  </div>
                  <div class="column">
                    <p className="footer-2">8:00AM - 6:00PM</p>
                    <p className="footer-2">8:00AM - 6:00PM</p>
                    <p className="footer-2">8:00AM - 6:00PM</p>
                    <p className="footer-2">8:00AM - 6:00PM</p>
                    <p className="footer-2">8:00AM - 6:00PM</p>
                    <p className="footer-2">By Appointment Only</p>
                    <p className="footer-2">By Appointment Only</p>
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column className="footer-column">
                  <img  
                    src={dlogo} 
                    alt="Logo" 
                    className="logo-2"/>
              </Grid.Column>
              <GridColumn className="footer-column2">
              <Grid className="grid-2">
                  <GridRow className="grow-2">
                    <img  
                      src={ABR} 
                      alt="Logo"   
                      className="logo-4"/>      
                    <img  
                      src={CDPE} 
                      alt="Logo" 
                      className="logo-3"/>
                  </GridRow>
                  <GridRow className="grow-2">
                    <img  
                      src={CRS} 
                      alt="Logo" 
                      className="logo-5"/>
                    <img  
                      src={EHO} 
                      alt="Logo" 
                      className="logo-5"/>
                  </GridRow>
                  <GridRow className="grow-2">
                  <img  
                    src={ESBA} 
                    alt="Logo" 
                    className="logo-3"/>
                  <img  
                    src={SRES} 
                    alt="Logo" 
                    className="logo-4"/>
                  </GridRow>
                </Grid>
              </GridColumn>
            </Grid>
          </Container>
        </section>
        <footer className="footer-bottom">
          <Container textAlign="center">
            <p className="foot-text">&copy; 2024 Delta Property Management Group, LLC. All rights reserved.</p>
          </Container>
        </footer>
      </div>
  );
};

export default Layout;