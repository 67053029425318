import React from 'react';
import './OurServices.css';
import 'semantic-ui-css/semantic.min.css';
import { Grid, GridColumn, GridRow } from 'semantic-ui-react';
import { Helmet } from 'react-helmet-async'

const OurServices = () => {
  return (
    <div className="pricing-page">
      <Helmet>
        <head>Inquiring Owners</head>
        <meta name="description" content="Delta Property Management Group (Delta PMG) is part of the Delta Group of companies. 
            We provide services to both residential and commercial customers. Delta PMG is a premier 
            property management maintenace and construction company services Central Pennsylvania for 
            over 40 years. No matter what size job, we have the skilled tradesmen to handle small 
            repairs, remodeling, and construction projects. Delta PMG is a fully licensed contractor 
            in the state of Pennsylvania. We offer complete construction management and building services
            from design to completion in not only new construction but complete remodeling services as well."/>
        <link rel="canonical" href="/inquiring-owners/our-services" />
      </Helmet>
      <div className="pricing-container">
        <div className="pricing-header">
          <h1 className="size-change-owners">Service is Job One!</h1>
          <p>Delta Property Management Group (Delta PMG) is part of the Delta Group of companies. 
            We provide services to both residential and commercial customers. Delta PMG is a premier 
            property management maintenace and construction company services Central Pennsylvania for 
            over 40 years. No matter what size job, we have the skilled tradesmen to handle small 
            repairs, remodeling, and construction projects. Delta PMG is a fully licensed contractor 
            in the state of Pennsylvania. We offer complete construction management and building services
            from design to completion in not only new construction but complete remodeling services as well.</p>
        </div>
        <div> 
          <h2 className="included-header">
            INCLUDED SERVICES
          </h2>
        </div>
        <div className="pricing-services">
          <div className="pricing-column">
            <h4 className="header-color">PRE-MARKETING</h4>
            <ul className="custom-list">
              <li className="no-whitespace">Complete Rental Analysis</li>
              <li className="no-whitespace">Evaluation of Property</li>
              <li className="no-whitespace">Recommend Improvements</li>
              <li className="no-whitespace">We Oversee Improvements</li>
              <li className="no-whitespace">Set Optimal Rental Price</li>
            </ul>
          </div>
          <div className="pricing-column">
            <h4 className="header-color">DAILY MANAGEMENT</h4>
            <ul className="custom-list">
              <li className="no-whitespace">Handle Tenant Concerns</li>
              <li className="no-whitespace">In-House Maintenance Repairs</li>
              <li className="no-whitespace">Home Inspection Annually</li>
              <li className="no-whitespace">Enforce Terms of Lease</li>
            </ul>
          </div>
          <div className="pricing-column">
            <h4 className="header-color">SHOWING OF PROPERTY</h4>
            <ul className="custom-list">
              <li className="no-whitespace">Professional Photography</li>
              <li className="no-whitespace">Adds Placed on Real Estate Sites</li>
              <li className="no-whitespace">Personal Walkthroughs</li>
              <li className="no-whitespace">Signs Placed on Property</li>
            </ul>
          </div>
          <div className="pricing-column">
            <h4 className="header-color">ACCOUNTING</h4>
            <ul className="custom-list">
              <li className="no-whitespace">Rents Collected Monthly</li>
              <li className="no-whitespace">Easy Payments to Owner</li>
              <li className="no-whitespace">Monthly Statements of All Activity</li>
              <li className="no-whitespace">Deposits Held in Trusted Account</li>
              <li className="no-whitespace">1099 and Year-End Statement</li>
            </ul>
          </div>
          <div className="pricing-column">
            <h4 className="header-color">HEADACHE FREE</h4>
            <ul className="custom-list">
              <li className="no-whitespace">We Follow Fair Housing Laws</li>
              <li className="no-whitespace">We Handle Evictions</li>
              <li className="no-whitespace">Avoid Tenant Confrontations</li>
              <li className="no-whitespace">Annual Rent Review</li>
              <li className="no-whitespace">Yearly Negotiable Management Agreement</li>
            </ul>
          </div>
        </div>
        

            <Grid textAlign="center" className="commercial-pricing">
                <GridRow textAlign="center">
                    <div className="unit-type">
                        <h2>Licenses • Accreditations • Titles</h2>
                    </div>
                </GridRow>
                <GridRow className="lat-row">
                    <GridColumn className="lat-column">
                        <p>• Contractor License No. PA128324</p>
                    </GridColumn>
                    <GridColumn className="lat-column">
                      <p>• Master Electrician License No. 239</p>
                    </GridColumn>
                    <GridColumn className="lat-column">
                        <p>• PA Real Estate License No. RM421166</p>
                    </GridColumn>
                </GridRow>
                <GridRow className="lat-row">
                    <GridColumn className="lat-columns">
                        <p>• CDPE</p>
                    </GridColumn>
                    <GridColumn className="lat-columns">
                        <p>• SRES</p>
                    </GridColumn>
                    <GridColumn className="lat-columns">
                        <p>• CRS </p>
                    </GridColumn>
                    <GridColumn className="lat-columns">
                        <p>• ABR</p>
                    </GridColumn>
                    <GridColumn className="lat-columns">
                        <p>• ESBA </p>
                    </GridColumn>
                </GridRow>
            </Grid>
      </div>

<div className="pricing-container2">
  <div className="pricing-header">
    <h1 className="size-change-owners1">Service is Job One!</h1>
    <p>&nbsp;</p>
    <p className="mobile-IO-text">
      Delta Property Management Group (Delta PMG) is part of the Delta Group of companies.
      We provide services to both residential and commercial customers. Delta PMG is a premier
      property management maintenance and construction company servicing Central Pennsylvania for
      over 40 years. No matter what size job, we have the skilled tradesmen to handle small
      repairs, remodeling, and construction projects. Delta PMG is a fully licensed contractor
      in the state of Pennsylvania. We offer complete construction management and building services
      from design to completion in not only new construction but complete remodeling services as well.
    </p>
  </div>
  <div>
    <h2 className="included-header">INCLUDED SERVICES</h2>
  </div>

  {/* Use Semantic UI Grid to center the columns */}
  <Grid centered textAlign="left" stackable>
    <GridRow className="header-color1">
    <p>&nbsp;</p>
      <h4 className="header-color1">PRE-MARKETING</h4>
    </GridRow>
    <GridRow>
      <ul className="custom-list1">
        <li className="no-whitespace">Complete Rental Analysis</li>
        <li className="no-whitespace">Evaluation of Property</li>
        <li className="no-whitespace">Recommend Improvements</li>
        <li className="no-whitespace">We Oversee Improvements</li>
        <li className="no-whitespace">Set Optimal Rental Price</li>
      </ul>
    </GridRow>
    
    <GridRow className="header-color1">
      <h4 className="header-color1">DAILY MANAGEMENT</h4>
    </GridRow>
    <GridRow>
      <ul className="custom-list1">
        <li className="no-whitespace">Handle Tenant Concerns</li>
        <li className="no-whitespace">In-House Maintenance Repairs</li>
        <li className="no-whitespace">Home Inspection Annually</li>
        <li className="no-whitespace">Enforce Terms of Lease</li>
      </ul>
    </GridRow>

    <GridRow className="header-color1">
      <h4 className="header-color1">SHOWING OF PROPERTY</h4>
    </GridRow>
    <GridRow>
      <ul className="custom-list1">
        <li className="no-whitespace">Professional Photography</li>
        <li className="no-whitespace">Adds Placed on Real Estate Sites</li>
        <li className="no-whitespace">Personal Walkthroughs</li>
        <li className="no-whitespace">Signs Placed on Property</li>
      </ul>
    </GridRow>

    <GridRow className="header-color1">
      <h4 className="header-color1">ACCOUNTING</h4>
    </GridRow>
    <GridRow>
      <ul className="custom-list1">
        <li className="no-whitespace">Rents Collected Monthly</li>
        <li className="no-whitespace">Easy Payments to Owner</li>
        <li className="no-whitespace">Monthly Statements of All Activity</li>
        <li className="no-whitespace">Deposits Held in Trusted Account</li>
        <li className="no-whitespace">1099 and Year-End Statement</li>
      </ul>
    </GridRow>

    <GridRow>
      <h4 className="header-color1">HEADACHE FREE</h4>
    </GridRow>
    <GridRow>
      <ul className="custom-list1">
        <li className="no-whitespace">We Follow Fair Housing Laws</li>
        <li className="no-whitespace">We Handle Evictions</li>
        <li className="no-whitespace">Avoid Tenant Confrontations</li>
        <li className="no-whitespace">Annual Rent Review</li>
      </ul>
    </GridRow>
  </Grid>

  <Grid textAlign="center" className="commercial-pricing">
    <GridRow textAlign="center">
      <div className="unit-type">
        <h2>Licenses • Accreditations • Titles</h2>
      </div>
    </GridRow>
    <GridRow className="lat-row">
      <GridColumn className="lat-column">
        <p>• Contractor License No. PA128324</p>
      </GridColumn>
      <GridColumn className="lat-column">
        <p>• Master Electrician License No. 239</p>
      </GridColumn>
      <GridColumn className="lat-column">
        <p>• PA Real Estate License No. RM421166</p>
      </GridColumn>
    </GridRow>
    <GridRow className="lat-row">
      <GridColumn className="lat-columns">
        <p>• CDPE</p>
      </GridColumn>
      <GridColumn className="lat-columns">
        <p>• SRES</p>
      </GridColumn>
      <GridColumn className="lat-columns">
        <p>• CRS </p>
      </GridColumn>
      <GridColumn className="lat-columns">
        <p>• ABR</p>
      </GridColumn>
      <GridColumn className="lat-columns">
        <p>• ESBA </p>
      </GridColumn>
    </GridRow>
  </Grid>
</div>

    </div>
  );
};

  export default OurServices;