import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

function HarrisburgRedirect() {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to the home page after a short delay (adjust as needed)
    const timer = setTimeout(() => {
      navigate('/');
    }, 50); // Use a small delay for the redirect
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div>
      <Helmet>
        <title>Harrisburg Area Property Management | Delta PMG</title>
        <meta name="description" content="Delta PMG offers premier property management services in the Harrisburg area." />
      </Helmet>
      <h1>Harrisburg Area Property Management</h1>
      <p>&nbsp;</p>
      <p>Connecting to Delta PMG home...</p>
    </div>
  );
}

export default HarrisburgRedirect;