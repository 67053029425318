import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'

const NoPage = () => {
    return (
      <div className="trying-to-move">
        <Helmet>
        <head>Page Not Found</head>
        <meta name="description" content="The Delta Property Management Group page you are looking
         for does not exist. Please visit www.deltapmg.com to visit our home page to get access to all tabs."/>
        <link rel="canonical" href="/" />
      </Helmet>
        <h1 className="nopage-padding">Sorry The Page You Are Looking For Does Not Exist</h1>
        <Link to="/" className="trying-to-move">
          <Button className="pButton-nopage">
            Home Page
          </Button>
        </Link>
      </div>
    
    );
  };
  
  export default NoPage;